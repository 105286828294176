import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import CryptoJS from 'crypto-js';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';




import { Autoplay, Pagination , Navigation,FreeMode } from 'swiper/modules';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Leftcontainer = () => {
const [sugg, setsugg] = useState([])
  useEffect(()=>{

    fetch(`${process.env.REACT_APP_FETCH_API_ADDRESS}/getmy2`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },body:JSON.stringify({search:"Spiritual"})
    }).then((res)=>res.json()).then((ele)=>{
  
      setsugg(ele)})
    },[])

    function sliceFiveWords(inputString) {
      // Split the string into an array of words
      var words = inputString.split(" ");
  
      // Select the first five words and join them back into a string
      var slicedString = words.slice(0, 5).join(" ");
  
      return slicedString;
  }
  
  return (
    <>

<section className='d-flex justify-content-center'>
<div className="leftcontainer">
  <div className="top-heading">
    <h4 className='fw-bolder fs-6'><i className="fa-solid fa-book-bookmark"></i> Spiritual/Self-help Books</h4>
    <Link to="/product/Spiritual"><h1><i class="fa-solid fa-fire text-warning "></i> View All</h1></Link>

  </div>

  <div className="bottom-thing">
<Swiper


        slidesPerView="auto"
        spaceBetween={5}
 navigation={true}

 
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination,Autoplay,Navigation]}
        className="mySwiper"
        >



{
  sugg  && sugg.map((ele)=>{
    
    return <SwiperSlide key={ele._id}>
    <Link className='text-decoration-none mt-2 text-dark d-flex flex-column align-items-center justify-content-center' to={`/productpage/${encodeURIComponent(CryptoJS.AES.encrypt(ele._id, process.env.REACT_APP_PRODUCT_KEY).toString())}`}>

    <img src={ele.bimg1} alt="bimg"  />
    <p className='fw-bolder fs-6'>{sliceFiveWords(ele.booktitle)}</p>
    </Link>
    <div className='d-flex'>

   
    <h4 className='text-danger fw-bolder  fs-6 text-decoration-line-through d-flex'><i className="fa-solid fa-indian-rupee-sign"></i>{ele.mrp}</h4>
                    &nbsp; <h4 className='text-primary fw-bolder fs-6 d-flex '><i className="fa-solid fa-indian-rupee-sign"></i>{ele.actualprice}</h4>
       &nbsp; <h4 className='text-success fw-bolder fs-6 d-flex '>{ele.discount}% OFF</h4>
    </div>
  </SwiperSlide>
})
}

       
      </Swiper>
</div>
</div>
</section>
    </>
  )
}

export default Leftcontainer